import { Team } from '@microsoft/mgt-react';
import axios, { AxiosError, AxiosResponse } from "axios";
import { APIM, ProductConfiguration } from '../common/constants';
import { ServicePrincipals, TeamsAssignment, AppRoleAssignments, EasyCallReportConfiguration, EasyDirectoryConfiguration, ViewSelect, OrgView, EasyTag, Role, EasyDirectoryFeatures } from '../common/interfaces';
import { Channel } from '@microsoft/microsoft-graph-types'

export class ConfigurationService {
    constructor(access_token?: string) {
        if (access_token) {
            if (access_token.startsWith("Bearer ")) {
                this.accessToken = access_token;
            }
            else {
                this.accessToken = "Bearer " + access_token;
            }
        }
    }

    accessToken: string;

    async timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async GetTeamInformation(selectedTeams: string): Promise<Team> {
        try {
            if (selectedTeams === undefined || selectedTeams === null)
                return null;

            const response = await axios.get('https://graph.microsoft.com/v1.0/teams/' + encodeURIComponent(selectedTeams), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async GetServicePrincipals(appId: string): Promise<ServicePrincipals> {
        try {
            const response = await axios.get("https://graph.microsoft.com/v1.0/servicePrincipals?$filter=appId+eq+'"
                + appId
                + "'&$select=id,displayName,appId,appOwnerOrganizationId,createdDateTime,displayName,appRoles,oauth2PermissionScopes", {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async CheckAppRoleAssignments(appId: string, token: string): Promise<AppRoleAssignments> {
        try {
            const response = await axios.get("https://graph.microsoft.com/v1.0/servicePrincipals(appId=%27"
                + appId
                + "%27)/appRoleAssignments", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async appRoleAssignedTo(appId: string, token: string): Promise<AppRoleAssignments> {
        try {
            const response = await axios.get("https://graph.microsoft.com/v1.0/servicePrincipals(appId=%27"
                + appId
                + "%27)/appRoleAssignedTo", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            return response.data;
        } catch (e: unknown) {
            console.log(e);
            return null;
        }
    }

    async GetChannelInformation(selectedTeams: string, selectedChannel: string): Promise<Channel> {
        if (selectedTeams === undefined || selectedTeams === null || selectedChannel === undefined || selectedChannel === null)
            return null;

        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/teams/' + encodeURIComponent(selectedTeams) + '/channels/' + encodeURIComponent(selectedChannel), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;

        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async IsTeamsAdmin(token: string): Promise<boolean> {
        try {
            await axios.get(APIM + 'easycallreport/admin/callqueues', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            return true;
        } catch (e: unknown) {
            return false;
        }
    }

    // ProductConfiguration.EasyCallReport.Admin.ElevatedScope
    async getCallQueues(token: string): Promise<any> {
        try {
            const response = await axios.get(APIM + 'easycallreport/admin/callqueues', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async getAutoAttendants(token: string): Promise<any> {
        try {
            const response = await axios.get(APIM + 'easycallreport/admin/autoattendants', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async GetConfiguration(): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.get(APIM + 'easycallreport/admin/configuration', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async DeleteEntraIdContacts() {
        try {
            const response = await axios.delete(APIM + 'easydirectory/delete/mailbox/entraId', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async DeleteSharedMailbox(sharedMaiboxId: string) {
        try {
            const response = await axios.post(APIM + 'easydirectory/delete/mailbox/shared', { sharedMailboxId: sharedMaiboxId }, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async GetEasyDirectoryConfiguration(accessToken): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.get(APIM + 'easydirectory/admin/configuration', {
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e) {
            throw new AxiosError(e as string);
        }
    }
    async GetOrganisationViews(): Promise<ViewSelect[]> {
        try {
            const response = await axios.get(APIM + 'easydirectory/admin/customViews', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            // Check if response.data exists and is an array
            if (!response?.data || !Array.isArray(response.data)) {
                console.error('Invalid response data format:', response?.data);
                return [];
            }

            // Map the data with type safety
            const views: ViewSelect[] = response.data.map((user: ViewSelect) => ({
                userId: user.userId,
                views: Array.isArray(user.views) ? user.views.map(view => ({
                    ...view,
                    searchTemplate: view.searchTemplate ? 
                        JSON.stringify(view.searchTemplate) : 
                        ""
                })) : []
            }));

            return views;

        } catch (e: unknown) {
            console.error('Error in GetOrganisationViews:', e);
            return [];
        }
    }

    async UpdateFullConfiguration(Config: EasyCallReportConfiguration): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/admin/configuration', Config, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateConfiguration(Property: string, Value: string): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/admin/configuration', JSON.parse("{\"" + Property + "\": " + Value + "}"), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateFullEDConfiguration(Config: EasyDirectoryConfiguration): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/configuration', Config, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }
    async UpdateOrgViews(id: string, orgViews: OrgView[]): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/' + id + '/orgViews', orgViews, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateOrgTags(id: string, orgTags: EasyTag[]): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/' + id + '/orgTags', orgTags, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }
    async UpdateRoles(id: string, roles: Role[]): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/' + id + '/roles', roles, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateEDFeatures(settings: EasyDirectoryFeatures): Promise<EasyDirectoryFeatures> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/configuration/settings', settings, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateEasyDirectoryConfiguration(Property: string, Value: string): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easydirectory/admin/configuration', JSON.parse("{\"" + Property + "\": " + Value + "}"), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async provisioningElasticSearch(): Promise<any> {
        try {
            const response = await axios.post(APIM + 'easydirectory/admin/provisioning', {}, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            }).catch(error => {
                return error.response;
            })
            return response;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async ImportPersonalMailbox(Config: EasyDirectoryConfiguration): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.post(APIM + 'easydirectory/admin/import/personalMailbox', Config, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async ImportEntraId(Config: EasyDirectoryConfiguration): Promise<EasyDirectoryConfiguration> {
        try {
            const response = await axios.post(APIM + 'easydirectory/admin/importEntraId', Config, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async ImportSharedMailboxes(sharedMaiboxId: string): Promise<any[]> {

        try {
            const response = await axios.post(APIM + 'easydirectory/admin/importSharedMailbox', { sharedMailboxId: sharedMaiboxId }, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async UpdateChannelAssociation(TeamsAssignment: TeamsAssignment): Promise<AxiosResponse> {
        try {
            const response = await axios.post(APIM + 'easycallreport/admin/configuration/updateChannelAssociation', TeamsAssignment, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async SendConfigurationChangeNotification(configChange: { tenantId: string, channelId: string }): Promise<AxiosResponse> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/notifyConfiguration', configChange, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async RenewSubscription(): Promise<any> {
        try {
            const response = await axios.post(APIM + 'easycallreport/register', {
                "changeType": "updated",
                "notificationUrl": ProductConfiguration.EasyCallReport.NotificationUrl,
                "resource": "/communications/callRecords",
                "clientState": "TeamsCallReceiver"
            }, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }
}