import { useState, useCallback, useContext, useEffect } from 'react';
import { EuiAvatar, EuiLoadingSpinner, EuiTreeView, EuiFieldText, EuiInputPopover, EuiProvider } from '@elastic/eui';
import { GraphService } from '../services/GraphService';
import { EasyContext } from './context/EasyContext';
import { Channel } from '@microsoft/microsoft-graph-types'
import { ConfigurationService } from '../services/ConfigurationService';

interface TeamsChannelPickerProps {
    onChannelSelect: (teamId: string, channelId: string) => void;
    selectedTeam?: string;
    selectedChannel?: string;
    readonly?: boolean;
}

const TeamsChannelPicker: React.FC<TeamsChannelPickerProps> = ({
    onChannelSelect,
    selectedTeam,
    selectedChannel,
    readonly
}) => {
    const { accessToken } = useContext(EasyContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [teams, setTeams] = useState([]);
    const [channels, setChannels] = useState<{ [teamId: string]: Channel[] }>({});
    const [isLoading, setIsLoading] = useState(false);
    const [expandedTeams, setExpandedTeams] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<{ teamLabel: string, channelLabel: string }>(null);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const graphService = new GraphService(accessToken, false);
    const configurationService = new ConfigurationService(accessToken);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
        const loadInitialSelection = async () => {
            if (!selectedTeam || !selectedChannel) {
                searchTeams('');
                setIsInitialLoading(false);
                return;
            }

            try {
                const team = await configurationService.GetTeamInformation(selectedTeam);
                const channel = await configurationService.GetChannelInformation(selectedTeam, selectedChannel);

                if (team && channel) {
                    setSelectedOption({
                        teamLabel: team.displayName,
                        channelLabel: channel.displayName
                    });

                    // Pre-load channels for the selected team
                    const channels = await graphService.getChannels(selectedTeam);
                    setChannels(prev => ({
                        ...prev,
                        [selectedTeam]: channels
                    }));
                    setExpandedTeams([selectedTeam]);
                }
            } catch (error) {
                console.error('Failed to load initial selection:', error);
            } finally {
                setIsInitialLoading(false);
            }
        };

        loadInitialSelection();
    }, [selectedTeam, selectedChannel]);

    const searchTeams = useCallback(async (searchTerm: string) => {
        setIsLoading(true);

        try {
            const teams = await graphService.searchTeams(searchTerm);

            const teamsWithChannels = await Promise.all(teams?.map(async (team) => {
                const channels = await graphService.getChannels(team.id);
                return { ...team, channels };
            }));

            setTeams(teamsWithChannels);
        } catch (error) {
            console.error('Failed to search teams:', error);
        } finally {
            setIsLoading(false);
        }
    }, [graphService]);

    const handleTeamExpand = async (teamId: string) => {
        if (!expandedTeams.includes(teamId)) {
            //await loadChannels(teamId);
            setExpandedTeams([...expandedTeams, teamId]);
        }
    };

    const renderTreeNodes = () => {
        return teams.map(team => ({
            label: team.displayName,
            id: team.id,
            icon: <EuiAvatar size='s' name={team.displayName} />,
            children: team.channels?.filter(c => c.membershipType === "standard")?.map((channel: Channel) => ({
                label: channel.displayName,
                id: channel.id,
                callback: () => {
                    onChannelSelect(team.id, channel.id);
                    setSelectedOption({ teamLabel: team.displayName, channelLabel: channel.displayName });
                    closePopover();
                }
            })) || [],
            isExpanded: expandedTeams.includes(team.id)
        }));
    };

    if (isInitialLoading) {
        return <EuiLoadingSpinner size="l" />;
    }

    const onSearchClick = () => {
        setSelectedOption(null);
        setIsPopoverOpen(true);
    };

    const closePopover = () => {
        setIsPopoverOpen(false);
    };

    return (
        <>
            <EuiInputPopover
                input={
                    <EuiFieldText
                        readOnly={readonly}
                        placeholder="Search teams..."
                        value={selectedOption ? `${selectedOption.teamLabel} > ${selectedOption.channelLabel}` : searchTerm}
                        onChange={(e) => {
                            setSelectedOption(null);
                            const searchTerm = e.target.value || '';
                            setSearchTerm(searchTerm);
                            searchTeams(searchTerm);
                        }}
                        onClick={onSearchClick}
                        isLoading={isLoading}
                        fullWidth
                    />
                }
                fullWidth
                isOpen={isPopoverOpen}
                closePopover={closePopover}
                panelPaddingSize="s"
                anchorPosition="downLeft"
            >
                {isLoading ? (
                    <EuiLoadingSpinner size="m" />
                ) : (
                    <EuiTreeView
                        showExpansionArrows
                        items={renderTreeNodes()}
                        aria-label="Teams and Channels"
                    />
                )}

            </EuiInputPopover>

            {isLoading && <EuiLoadingSpinner size="l" />}
        </>
    );
};

export default TeamsChannelPicker;