import * as React from 'react'
import {
    CCard,
    CCardBody,
    CButton,
    CCardTitle,
    CCardText,
    CForm,
    CFormInput,
    CFormSelect,
    CCardSubtitle,
    CCol,
    CRow,
    CFormLabel,
    CFormTextarea,
    CLink,
    CAlert,
    CSpinner,
} from '@coreui/react'
import { useNavigate, useSearchParams } from "react-router-dom";
import CIcon from '@coreui/icons-react';
import { cilWarning } from '@coreui/icons';
import { Customer, Subscription } from '../../common/interfaces';
import { SubscriptionService } from '../../services/SubscriptionService';
import countries from '../../assets/country-by-abbreviation.json'

const SubscriptionOnboarding = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const store_token = searchParams.get("token");
    const subscriptionService = new SubscriptionService();

    const [isPageLoading, setIsPageLoading] = React.useState(false);
    const [loadingMsg, setLoadingMsg] = React.useState("");

    const [isError, setIsError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");

    const [validated, setValidated] = React.useState(false);
    const [subscriptionSaved, setSubscriptionSaved] = React.useState(false);
    const [subscription, setSubscription] = React.useState<Subscription>();

    React.useEffect(() => {
        setIsPageLoading(true);
        setLoadingMsg("Loading subscription...");
        const subscription = async () => await subscriptionService.getSubscriptionFromToken(store_token).then((subscription) => {
            setSubscription(subscription);
            setIsPageLoading(false);
        }).catch((e) => {
            setIsPageLoading(false);
            setIsError(true);
            setErrorMsg("We cannot load your subscription: " + e);
        });

        subscription();
    }, [store_token]);

    React.useEffect(() => {
        // Display success message and wait 
        if (subscriptionSaved === true) {
            console.log("Subscription: ", subscription);
            const subNameStartsWith = subscription.subscription_name.startsWith("Easy Directory");
            console.log("Subscription name starts with Easy Directory: ", subNameStartsWith);

            const offerIdStartsWith = subscription.offer_id.startsWith("easydirectory");
            console.log("Offer ID starts with easydirectory: ", offerIdStartsWith);

            if (subscription.offer_id.toLowerCase().startsWith("easydirectory")) {
                navigate('/subscriptions/onboarding/easydirectory');
            } else if (subscription.offer_id.toLowerCase().startsWith("easyconsole")) {
                navigate('/subscriptions');
            } else if (subscription.offer_id.toLowerCase().startsWith("easyconsole-preview")) {
                navigate('/subscriptions');
            } else if (subscription.offer_id.toLowerCase().startsWith("easycalling")) {
                navigate('/subscriptions/onboarding/easycalling');
            } else {
                navigate('/subscriptions/onboarding/easycallreport');
            }
        }
    }, [subscriptionSaved]);


    React.useEffect(() => {
        if (validated === true) {
            const saveSubscription = async () => await subscriptionService.postNewSubscription(store_token, subscription).then((subscription) => {
                if (subscription !== null) {
                    setSubscriptionSaved(true);
                }
                else {
                    // Raise error
                    setIsError(true);
                    setErrorMsg("We cannot save your subscription.");
                }
            }).catch((e) => {
                // Raise error
                setIsError(true);
                setErrorMsg("We cannot save your subscription: " + e);
            });

            saveSubscription();
        }
    }, [validated]);


    const handleSubmit = (event) => {
        const form = event.currentTarget

        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            return;
        }

        const newSubscription: Subscription = subscription;
        const companyInfo: Customer = {
            id: subscription.tenant_id ?? subscription.purchaser.tenant_id ?? subscription.beneficiary.tenant_id ?? "",
            name: form[5].value ?? "",
            address: form[6].value ?? "",
            city: form[7].value ?? "",
            postalCode: form[8].value ?? "",
            country: form[9].value ?? "",
            tenantId: subscription.tenant_id ?? subscription.purchaser.tenant_id ?? subscription.beneficiary.tenant_id ?? "",
            isMSStoreCustomer: true
        };

        newSubscription.customer = companyInfo;
        newSubscription.subscription_name = form[0].value ?? subscription.subscription_name;

        setSubscription(newSubscription);
        setValidated(true);

        event.preventDefault()
        event.stopPropagation()
    }

    return (store_token ?
        <div>
            <br />
            {isError ?
                <CAlert color="danger">
                    <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                    Error: {errorMsg}
                </CAlert> : <></>
            }
            {isPageLoading ?
                <div className="d-flex justify-content-center tcf-loading-background">
                    <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
                </div> : <></>
            }
            <h2>You&apos;re almost done 🥳</h2>
            <p>
                Thank you for purchasing a new subscription! We are thrilled to have you onboard. Let us guide you through the setup process and help you get started. Please let us know if you have any questions or concerns along the way. We are here to help you every step of the way.
            </p>
            <CForm className="row g-3 needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}>
                <CCard>
                    <CCardBody>
                        <CCardTitle>{subscriptionService.getSubscriptionProductTitle(subscription?.offer_id ?? "")} {subscriptionService.getSubscriptionProductPlan(subscription?.plan_id ?? "")}</CCardTitle>
                        <CCardSubtitle className="mb-2 text-medium-emphasis">Please review your purchase details below before confirming</CCardSubtitle>
                        <CCardText>
                            <br />
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="subscriptionTitle" className="col-sm-2 col-form-label">Subscription Title</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="subscriptionTitle"
                                        defaultValue={subscription?.subscription_name ?? ""}
                                        required
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="subscriptionStatus" className="col-sm-2 col-form-label">Subscription Status</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="subscriptionStatus"
                                        value={subscriptionService.getSubscriptionStatus(subscription?.state)}
                                        readOnly plainText
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="seatCount" className="col-sm-2 col-form-label">Seats</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="seatCount"
                                        value={subscription?.seatQuantity ?? 0}
                                        readOnly plainText
                                    />
                                </CCol>
                            </CRow>
                            <br />
                            <h5>Contact Details</h5>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="purchaserEmail" className="col-sm-2 col-form-label">Purchaser Email</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="purchaserEmail"
                                        value={subscription?.purchaser?.email ?? ""}
                                        readOnly plainText
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="beneficiaryEmail" className="col-sm-2 col-form-label">Beneficiary Email</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="beneficiaryEmail"
                                        value={subscription?.beneficiary?.email ?? ""}
                                        readOnly plainText
                                    />
                                </CCol>
                            </CRow>
                        </CCardText>
                    </CCardBody>
                </CCard>
                <br />
                <CCard>
                    <CCardBody>
                        <CCardTitle>Company Details</CCardTitle>
                        <CCardSubtitle className="mb-2 text-medium-emphasis">Please provide some additional details about your company.</CCardSubtitle>
                        <CCardText>
                            <br />
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="company" className="col-sm-2 col-form-label">Company</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="company"
                                        required
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="address" className="col-sm-2 col-form-label">Address</CFormLabel>
                                <CCol sm={10} >
                                    <CFormTextarea
                                        id="address"
                                        rows={2}
                                        required
                                    ></CFormTextarea>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="city" className="col-sm-2 col-form-label">City</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="city"
                                        required
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="postalCode" className="col-sm-2 col-form-label">Postal Code</CFormLabel>
                                <CCol sm={10} >
                                    <CFormInput
                                        type="text"
                                        id="postalCode"
                                        required
                                    />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CFormLabel htmlFor="tenantCountry" className="col-sm-2 col-form-label">Country</CFormLabel>
                                <CCol sm={10} >
                                    <CFormSelect
                                        id="tenantCountry"
                                        text="The country in which the your organization is based."
                                        required>
                                        {
                                            countries.map((country) => {
                                                if (country.abbreviation === "CH") {
                                                    return (<option selected key={country.abbreviation} value={country.abbreviation}>{country.country}</option>)
                                                }
                                                else {
                                                    return (<option key={country.abbreviation} value={country.abbreviation}>{country.country}</option>);
                                                }
                                            })
                                        }
                                    </CFormSelect>
                                </CCol>
                            </CRow>
                        </CCardText>
                    </CCardBody>
                </CCard>
                <br />
                <CCard>
                    <CCardBody>
                        <CCardTitle>Next Steps</CCardTitle>
                        <CCardText>
                            <p><b>Important:</b> After clicking &quot;Complete Setup&quot; the subscription will be activated. You will receive an Email with instructions how to setup {subscriptionService.getSubscriptionProductTitle(subscription?.offer_id ?? "")}.
                                Below you also find the installation guide and manual of {subscriptionService.getSubscriptionProductTitle(subscription?.offer_id ?? "")}. Please note, that you need to have Administrator priviledges in you tenant, to proceed. If you can&apos;t give the consent in the next step, please forward the Email to your Administrator. After the consent of the App in your tenant, you can access the configuration portal.</p>
                            <CLink target="_blank" href="https://docs.easycallreport.easyplatform.app/getting-started/installation-guide">Installation Guide</CLink>
                        </CCardText>
                    </CCardBody>
                </CCard>


                <br />
                <br />
                <CRow className="justify-content-start">
                    <CCol xs={{ span: 4 }}><div className="p-4"><CButton id="submitButton" color="primary" type="submit">Complete Setup</CButton></div></CCol>
                    <CCol xs={{ span: 4 }}><div className="p-4"><CButton id="cancelButton" color="light" >Cancel</CButton></div></CCol>
                </CRow>
            </CForm>
            <br />
            <br />
            <br />
            <br />
        </div> : <div>
            <br />
            <h2>You need a valid Microsoft Store token </h2>
            <br />
            <CAlert color="danger">
                <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                <div>Thank you for your interest in onboarding a new product! You need to have a valid Microsoft Store token, to proceed. Please go to Microsoft App Source and buy a new subscription.</div>
            </CAlert>
        </div>
    )
}

export default SubscriptionOnboarding
