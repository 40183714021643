/**
 * -------------------------------------------------------------------------------------------
 * GraphService based on @microsoft/mgt-element & @microsoft/mgt-components/mgt-teams-channel-picker.graph.ts
 * @author Andy Nopparat Bui - TwinCap First AG
 * -------------------------------------------------------------------------------------------
 */

import { prepScopes, CollectionResponse, Providers } from '@microsoft/mgt-element';
import { EasyPickerDropdown, EasyGroup, Planner, Bucket } from '../common/interfaces';
import { Group, Channel } from '@microsoft/microsoft-graph-types'
import axios, { AxiosError } from 'axios';

const provider = Providers.globalProvider;
const channelReadScopes = ['Channel.ReadBasic.All'];


export class GraphService {
    private groupsMemberReadScopes = [
        'Group.Read.All'
    ];

    accessToken: string;

    constructor(access_token: string, isPlannerActivated: boolean) {
        if (access_token !== null) {
            if (access_token.startsWith("Bearer ")) {
                this.accessToken = access_token;
            }
            else {
                this.accessToken = "Bearer " + access_token;
            }
        }

        if (isPlannerActivated && isPlannerActivated === true) {
            this.groupsMemberReadScopes = [
                'Group.Read.All',
                'Tasks.ReadWrite'
            ];
        }
    }


    /**
     * async promise, returns all Teams associated with the user logged in
     * Based on @microsoft/mgt-components/mgt-teams-channel-picker.graph.ts from Microsoft
     * @returns {Promise<EasyPickerDropdown>}
     * @memberof Graph
     */
    async getAllGroups(): Promise<EasyPickerDropdown> {
        const graph = provider.graph;
        const groups = (await graph.client
            .api('groups')
            .select([
                'id',
                'displayName',
                'visibility'
            ])
            .filter("resourceProvisioningOptions/Any(x:x+eq+'Team')")
            .top(1000)
            .count(true)
            .middlewareOptions(prepScopes(...this.groupsMemberReadScopes))
            .get()) as CollectionResponse<EasyGroup>;
        return { easyGroups: groups?.value, nextLink: groups['@odata.nextLink'] };
    }

    /**
     * async promise, preload 10 Group associated with the user logged in
     * @returns {Promise<Group[]>}
     */
    async preLoadGroups(): Promise<EasyPickerDropdown> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/groups?$top=10&$count=true&$select=id,displayName,visibility&$filter=resourceProvisioningOptions/Any(x:x+eq+\'Team\')', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<EasyGroup>;
            return { easyGroups: data?.value, nextLink: data['@odata.nextLink'] };
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    /**
     * async promise, returns next loads of teams
     * @param skip
     * @returns {Promise<EasyGroup[]>}
     */
    async getMoreGroups(nextLink: string): Promise<EasyPickerDropdown> {
        try {
            const response = await axios.get(nextLink, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<EasyGroup>;
            return { easyGroups: data?.value, nextLink: data['@odata.nextLink'] };
        } catch (e: unknown) {
            return { easyGroups: [], nextLink: "" };
        }
    }

    /**
    * async promise, returns all Teams that starts with the search input
    * @param search
    * @returns {Promise<EasyGroup[]>}
    */
    async getGoupsByName(search): Promise<EasyGroup[]> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/groups?$top=100&$count=true&$select=id,displayName,visibility&$filter=resourceProvisioningOptions/Any(x:x+eq+\'Team\') and startswith(displayName,%27' + encodeURIComponent(search.replace("'", "")) + '%27)', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<EasyGroup>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }



    /**
     * Creates an array of DropdownItems from an array of Group populated with channels.
     * Based on @microsoft/mgt-components/mgt-teams-channel-picker.graph.ts from Microsoft.
     * @param groupId 
     * @returns {Promise<DropdownItem[]>} a promise that resolves to an array of DropdownItems
     */
    async getChannelsByGroup(groupId: string): Promise<Channel[]> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/teams/' + groupId + '/channels?$select=id,displayName,membershipType', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<Channel>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async searchTeams(search?: string): Promise<Group[]> {
        let query = 'https://graph.microsoft.com/v1.0/groups?$top=100&$count=true&$select=id,displayName,visibility&$filter=resourceProvisioningOptions/Any(x:x+eq+\'Team\') and startswith(displayName,%27' + encodeURIComponent(search.replace("'", "")) + '%27)';

        if (!search || search === '') {
            query = 'https://graph.microsoft.com/v1.0/groups?$top=100&$count=true&$select=id,displayName,visibility&$filter=resourceProvisioningOptions/Any(x:x+eq+\'Team\')';
        }

        try {
            const response = await axios.get(query, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<EasyGroup>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async getChannels(groupId: string): Promise<Channel[]> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/teams/' + groupId + '/channels?$select=id,displayName,membershipType', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<Channel>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    /**
     * Gets all Planner plans associated with a group
     * @param groupId 
     * @returns {Promise<Planner[]>} a promise that resolves to an array of DropdownItems
     */
    async getAssociatedPlannerPlans(groupId: string): Promise<Planner[]> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/groups/' + groupId + '/planner/plans?$select=id,title', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<Planner>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    /**
     * Gets all buckets in Planner plan
     * @param planId 
     * @returns {Promise<Planner[]>} a promise that resolves to an array of DropdownItems
     */
    async getAllBucketsFromPlanner(planId: string): Promise<Bucket[]> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/planner/plans/' + planId + '/buckets', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data as CollectionResponse<Bucket>;
            return data.value;
        } catch (e: unknown) {
            throw new AxiosError(e as string);
        }
    }

    async getUser(identification: string): Promise<any> {
        try {
            const response = await axios.get('https://graph.microsoft.com/v1.0/users/' + encodeURIComponent(identification), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });
    
            return response.data || null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Werfen Sie den Fehler mit zusätzlichen Informationen
                throw {
                    message: error.message,
                    status: error.response?.status,
                    data: error.response?.data
                };
            }
            // Für nicht-Axios-Fehler
            throw error;
        }
    }
    
}