export const APIM = process.env.REACT_APP_API_ENDPOINT
export const Version = "2.6"

const DefaultRedirectUrl = process.env.REACT_APP_ADMIN_LOGINURL;
const DefaultAuthority = 'https://login.microsoftonline.com/organizations';

export const ProductConfiguration = {
    AdminPortal: {
        ApplicationId: process.env.REACT_APP_PORTAL_APPID,
        Scopes: [ 'User.Read', 'Application.Read.All' ],
        RedirectUrl: DefaultRedirectUrl,
        Authority: process.env.REACT_APP_PORTAL_AUTHORITY ?? DefaultAuthority
    },
    EasyCallReport: {
        ApplicationId: process.env.REACT_APP_ECR_APPID,
        Scopes: ['Channel.ReadBasic.All', 'Group.Read.All'],
        RedirectUrl: process.env.REACT_APP_ECR_REDIRECTURL,
        NotificationUrl: process.env.REACT_APP_ECR_NOTIFICATIONURL,
        Admin: {
            ApplicationId: process.env.REACT_APP_ECR_ADMIN_APPID,
            Scopes: ['https://graph.microsoft.com/.default'],
            ElevatedScope: '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
            RedirectUrl: DefaultRedirectUrl,
            Authority: process.env.REACT_APP_ECR_AUTHORITY ?? DefaultAuthority
        }
    },
    EasyDirectory: {
        ApplicationId: process.env.REACT_APP_ED_APPID,
        Scopes: ['Channel.ReadBasic.All', 'Group.Read.All'],
        RedirectUrl: process.env.REACT_APP_ED_REDIRECTURL,
        Admin: {
            ApplicationId: process.env.REACT_APP_ED_ADMIN_APPID,
            Scopes: ['https://graph.microsoft.com/.default'],
            RedirectUrl: DefaultRedirectUrl,
            Authority: process.env.REACT_APP_ED_AUTHORITY ?? DefaultAuthority
        }
    }
}