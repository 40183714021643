import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { CButton, CCard, CCardBody, CCardImage, CCardTitle, CContainer, CSpinner } from "@coreui/react";
import { useEffect, useState, Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "../../routes";
import { ConfigurationService } from "../../services/ConfigurationService";
import { SubscriptionService } from "../../services/SubscriptionService";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";
import { EasyContext } from "./EasyContext"
import { Login, Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-react";

import TcfImg from '../../assets/images/twincap_first_ag_cover.jpg'
import { EasyProductLicenses } from "../../common/interfaces";


interface EasyContextHandlerProps {
    authScopes: string[]
}

const EasyContextHandler: React.FC<EasyContextHandlerProps> = ({ authScopes }) => {
    const { instance, accounts, inProgress } = useMsal();
    const [subscriptions, setSubscriptions] = useState([]);
    const [accessToken, setAccessToken] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [userId, setUserId] = useState("");
    const [productLicenses, setProductLicenses] = useState<EasyProductLicenses>();
    const [userDisplayName, setUserDisplayName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [isTokenValid, setIsTokenValid] = useState(false);

    const request = {
        scopes: authScopes,
        account: accounts[0]
    };

    useEffect(() => {
        const validateToken = async () => {
            if (!instance || !accounts[0]) {
                setIsLoading(false);
                return;
            }

            try {
                await instance.initialize();
            } catch (e) {
                console.log(e);
            }

            try {
                // Initialize MGT Provider
                Providers.globalProvider = new SimpleProvider((scopes: string[]) => {
                    return instance.acquireTokenSilent({
                        account: accounts[0],
                        scopes: scopes
                    }).then(response => {
                        return response.accessToken;
                    });
                });

                Providers.globalProvider.setState(ProviderState.SignedIn);

                const auth = await instance.acquireTokenSilent(request);

                if (auth?.accessToken) {
                    setAccessToken(auth.accessToken);
                    setTenantId(auth.tenantId);
                    setUserDisplayName(auth.account.name);
                    setUserId(auth.account.username);
                    setIsTokenValid(true);

                    const subscriptionService = new SubscriptionService(auth.accessToken);
                    const subscriptions = await subscriptionService.getSubscriptions();
                    setSubscriptions(subscriptions);

                    const EasyCallReportInfo = subscriptionService.getEasyCallReportLicenseInfo(subscriptions);
                    const EasyDirectoryInfo = subscriptionService.getEasyDirectoryLicenseInfo(subscriptions);

                    setProductLicenses({
                        EasyCallReport: EasyCallReportInfo,
                        EasyDirectory: EasyDirectoryInfo
                    });
                }
            } catch (e) {
                try {
                    const auth = await instance.acquireTokenPopup(request);
                    if (auth?.accessToken) {
                        setAccessToken(auth.accessToken);
                        setTenantId(auth.tenantId);
                        setUserDisplayName(auth.account.name);
                        setUserId(auth.account.username);
                        setIsTokenValid(true);
                    }
                } catch (popupError) {
                    setError(popupError as Error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        validateToken();
    }, [instance, accounts]);

    if (isLoading) {
        return <div className="body flex-grow-1 px-3"><CContainer lg>
            <div className="d-flex justify-content-center">
                <CSpinner /> Authenticating...
            </div>
        </CContainer></div>;
    }

    if (error) {
        return <div className="body flex-grow-1 px-3"><CContainer lg>
            <div className="d-flex justify-content-center">
                <div>Error: {error.message}</div>
            </div>
        </CContainer></div>;
    }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <AuthenticatedTemplate>
                {isTokenValid ? (
                    <EasyContext.Provider value={{ accessToken, productLicenses, subscriptions, tenantId, userDisplayName, userId }}>
                        <AppHeader />
                        <div className="body flex-grow-1 px-3">
                            <CContainer lg>
                                <Suspense fallback={<CSpinner />}>
                                    <Routes>
                                        {routes.map((route, idx) => {
                                            return (
                                                route.element && (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        element={<route.element />}
                                                    />
                                                )
                                            )
                                        })}
                                        <Route path="/" element={<Navigate to="dashboard" replace />} />
                                    </Routes>
                                </Suspense>
                            </CContainer>
                        </div>
                        <AppFooter />
                    </EasyContext.Provider>
                ) : (
                    <CContainer lg>
                        <div className="d-flex justify-content-center">
                            <CSpinner /> Authenticating...
                        </div>
                    </CContainer>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader />
                    <br />
                    <div className="body flex-grow-1 px-3">
                        <CContainer lg>
                            <CCard>
                                <CCardImage orientation="top" src={TcfImg} className="tcf-card-img" />
                                <CCardBody>
                                    <CCardTitle>Easy Platform Login</CCardTitle>
                                    <CButton onClick={() => instance.loginRedirect(request)}>Login</CButton>
                                </CCardBody>
                            </CCard>
                        </CContainer>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </div>

    )
}

export default EasyContextHandler