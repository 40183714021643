import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ProductConfiguration } from '../common/constants';
import EasyContextHandler from '../components/context/EasyContextHandler';
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";

const msalConfigurationCenterConfig: Configuration = {
  auth: {
    clientId: ProductConfiguration.AdminPortal.ApplicationId,
    redirectUri: ProductConfiguration.AdminPortal.RedirectUrl,
    authority: ProductConfiguration.AdminPortal.Authority
  },
};

const portalPca = new PublicClientApplication(msalConfigurationCenterConfig);

const DefaultAuthenticatedLayout = () => { 
  return (
    <MsalProvider instance={portalPca}>
      <EasyContextHandler authScopes={ProductConfiguration.AdminPortal.Scopes} />
    </MsalProvider>
  )
}

export default DefaultAuthenticatedLayout
