import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ProductConfiguration } from '../common/constants';
import EasyContextHandler from '../components/context/EasyContextHandler';
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";

const msalConfigurationCenterConfig: Configuration = {
  auth: {
    clientId: ProductConfiguration.EasyDirectory.Admin.ApplicationId,
    redirectUri: ProductConfiguration.EasyDirectory.Admin.RedirectUrl,
    authority: ProductConfiguration.EasyDirectory.Admin.Authority
  },
};

const edPca = new PublicClientApplication(msalConfigurationCenterConfig);

const EasyDirectoryAuthenticatedLayout = () => {
  return (
    <MsalProvider instance={edPca}>
      <EasyContextHandler authScopes={ProductConfiguration.EasyDirectory.Admin.Scopes} />
    </MsalProvider>
  )
}

export default EasyDirectoryAuthenticatedLayout
